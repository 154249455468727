<script>
	// import MobileAV12Pass2MB from '~/assets/video/CITYFLO_001_V_2MB.av1.mp4?url';
	import MobileAV12Pass3600KB from '~/assets/video/CITYFLO_001_V_3600KB.av1.mp4?url';
	// import MobileAV12Pass4MB from '~/assets/video/CITYFLO_001_V_4MB.av1.mp4?url';
	import MobileHEVC2Pass4MB from '~/assets/video/CITYFLO_001_V_4MB.hevc.mp4?url';
	import MobileH2642Pass12MB from '~/assets/video/CITYFLO_001_V_12MB.h264.mp4?url';
	// import DesktopH2642Pass12MB from '~/assets/video/CITYFLO_001_H_12MB.h264.mp4';
	import DesktopVP914MB from '~/assets/video/CITYFLO_001_H_14MB.vp9.mp4?url';
	import DesktopAV110MB from '~/assets/video/CITYFLO_001_H_10MB.av1.mp4?url';
	import DesktopH2642Pass24MB from '~/assets/video/CITYFLO_001_H_24MB.h264.mp4?url';
	import { isMobile } from '~/utils/store';
	// import Mobile from '~/assets/video/CITYFLO_001_V.mp4';
</script>

{#if $isMobile === false}
	<video
		autoplay
		class="w-full h-[100svh] object-cover object-bottom bg-gradient-to-tl from-white to-primary-200"
		loop
		muted
		playsinline
		preload="metadata"
		style="aspect-ratio: 1920 / 1080; filter: brightness(0.85);"
	>
		<source src={DesktopAV110MB} type="video/mp4; codecs=av01.0.05M.08" />
		<source src={DesktopVP914MB} type="video/mp4" />
		<source src={DesktopH2642Pass24MB} type="video/mp4; codecs=avc1.4D401E" />
		<!-- <source src={DesktopMp4} type="video/mp4" /> -->

		Your browser does not support the video tag.
	</video>
{:else if $isMobile === true}
	<video
		autoplay
		class="w-full h-[100svh] object-cover object-bottom bg-gradient-to-tl from-white to-primary-200"
		loop
		muted
		playsinline
		preload="metadata"
		style="aspect-ratio: 1080 / 1920;"
	>
		<!-- For Chrome Mobile -->
		<source src={MobileAV12Pass3600KB} type="video/mp4; codecs=av01.0.05M.08" />
		<!-- <source src={MobileAV12Pass4MB} type="video/mp4; codecs=av01.0.05M.08" /> -->
		<!-- <source src={MobileAV12Pass2MB} type="video/mp4; codecs=av01.0.05M.08" /> -->
		<!-- For Safari Mobile -->
		<!-- <source src={MobileHEVC2Pass3500KB} type="video/mp4" /> -->
		<source src={MobileHEVC2Pass4MB} type="video/mp4" />
		<!-- <source src={MobileHEVC2Pass2MB} type="video/mp4" /> -->
		<!-- <source src={MobileHEVC2Pass5MB} type="video/mp4" /> -->
		<!-- For Others -->
		<!-- <source src={MobileVP92Pass11MB} type="video/mp4; codecs=vp9" /> -->
		<!-- For Others -->
		<source src={MobileH2642Pass12MB} type="video/mp4; codecs=avc1.4D401E" />

		Your browser does not support the video tag.
	</video>
{:else}
	<!-- adding for fallback -->
	<div class="w-full h-[100svh] bg-gradient-to-tl from-white to-primary-200" />
{/if}
